import * as React from 'react';

import { locales, defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Section from '../components/Section';

function MissingPage({ data, pageContext }) {
  function getLocaleFromPath() {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname;
      for (const locale in locales) {
        if (pathname.startsWith(`/${locale}/`)) {
          return locale;
        }
      }
    }
    return defaultLocale;
  }

  return (
    <LocaleProvider locale={getLocaleFromPath()}>
      <Layout>
        <Hero content={'# Page not Found'} />
        <Section colorScheme="black">
          <div className="container pb-12">
            <p className="text-2xl">
              The page you are looking for has been removed or relocated.
            </p>
          </div>
        </Section>
      </Layout>
    </LocaleProvider>
  );
}

export default MissingPage;
